import config from "@/config/index";
export const mainRequests = {
  put,
  del,
  post,
  get,
  getDriver,
  postDriver,
  customPost,
  customGet,
  getAddress,
  postAddress,
  getDriverStatus,
  postDriverTeam,
  patchDriverTeam,
  deleteDriverTeam,
  getPodAddress
};
function handleResponse(response) {
  return response
    .text()
    .then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          //   logout()npm run serve
          location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
async function post(url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
    fetch(`${config.BASE_SERVER_ADMIN_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function put(url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
    fetch(`${config.BASE_SERVER_ADMIN_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function del(url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
    fetch(`${config.BASE_SERVER_ADMIN_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function get(url) {
  let promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    fetch(`${config.BASE_SERVER_ADMIN_PATH}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function getDriverStatus(url) {
  let promise = new Promise((resolve, reject) => {
    fetch(`${config.VUE_APP_DRIVER_API}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function postDriverTeam(url, body) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body)
    };
    fetch(`${config.VUE_APP_DRIVER_API}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function deleteDriverTeam(url) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" }
    };
    fetch(`${config.VUE_APP_DRIVER_API}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function patchDriverTeam(url, body) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body)
    };
    fetch(`${config.VUE_APP_DRIVER_API}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function getDriver(url) {
  let promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    fetch(`${config.BASE_DRIVER_ADMIN_PATH}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function postDriver(url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
    fetch(`${config.BASE_DRIVER_ADMIN_PATH}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function customPost(url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
    fetch(url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function getAddress(url) {
  let promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    fetch(`${config.BASE_PATH_ADDRESS}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function getPodAddress(url) {
  let promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    fetch(`${config.POD_PATH_ADDRESS}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function customGet(url) {
  let promise = new Promise((resolve, reject) => {
    fetch(url)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
async function postAddress(url, payload) {
  let promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
    fetch(`${config.BASE_PATH_ADDRESS}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
  let result = await promise;
  return result;
}
// async function getMain(url) {
//   let promise = new Promise((resolve, reject) => {
//     // const requestOptions = {
//     //   method: 'POST',
//     //   headers: { 'Content-Type': 'application/json' }
//     // }
//     fetch(`${config.BASE_SERVER_PATH}` + url)
//       .then(handleResponse)
//       .then(data => {
//         resolve(data);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
//   let result = await promise;
//   return result;
// }
