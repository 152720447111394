const BASE_SERVER_PATH = process.env.VUE_APP_ADMIN_BASE;
const BASE_DRIVER_SERVER_PATH =
  "https://us-central1-toktok-1df1f.cloudfunctions.net";
const VUE_APP_DRIVER_API = process.env.VUE_APP_DRIVER_API;
const BASE_WEB_MALL_PATH = "https://api.toktokmall.mn/api/v1";
export default {
  BASE_WEB_MALL_PATH: BASE_WEB_MALL_PATH,
  BASE_SERVER_ADMIN_PATH: BASE_SERVER_PATH,
  VUE_APP_ADMIN_API: process.env.VUE_APP_ADMIN_API,
  VUE_APP_DRIVER_API: VUE_APP_DRIVER_API,
  BASE_DRIVER_ADMIN_PATH: BASE_DRIVER_SERVER_PATH + "/driver",
  BASE_PATH_ADDRESS: "https://rest.toktok.mn/address/api",
  POD_PATH_ADDRESS:
    "https://6xfdkmjtuf.execute-api.ap-southeast-1.amazonaws.com/prod"
};
